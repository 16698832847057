import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

const loading = () => (
  
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));

class AuthGuard extends Component {
  constructor() {
    super();

    this.state = {
      token: null,
    };
  }

  componentDidMount() {
    //  window.addEventListener("beforeunload", (ev) =>
    //  {
    //    ev.preventDefault();
    // localStorage.clear();
    // return ev.returnValue = 'Are you sure you want to close?';
    //  });
  }

  UNSAFE_componentWillMount() {
    this.setState({ token: localStorage.getItem(`token`) });
  }

  render() {
    const { token } = this.state;
    const { children } = this.props;

    return token ? children : <Redirect to="/login" />;
  }
}

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <Register {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={props => (
                <AuthGuard {...props}>
                  <DefaultLayout {...props} />
                </AuthGuard>
              )}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
